
<template>
  <!-- 会员权益体系 -->
  <div>
    <div class="heading">
      <h3>数字化营销</h3>
      <p>
        数字化营销的发展基于业务的数字化和数据的治理需求，但真正实现用数字化营销转型的企业却凤毛麟角。微心动认为数字化营销驱动的增长是爆发式、自驱式和高效能的增长，在长期的数字化营销实践中，将与您的企业一起探索数字营销背后隐藏的潜藏机遇和成功因子。
      </p>
      <button @click="onapply()">申请试用</button>
    </div>
    <div class="content">
      <img src="../../assets/images/market01.png" />
      <div>
        <h6>
          微心动一体化营销云<br />
          数字化营销增长方案
        </h6>
        <p>
          数字化营销的核心是能够进行大规模的精准个性化营销，需要具备面向庞大客户群体的整体营销能力，需要有千人千面的个性化精准营销能力，尤其是当营销活动涉及到不同区域、不同渠道和不同商品品类时，这样的挑战尤为艰巨。
        </p>
        <p>
          微心动一体化营销云从数字化链接、数据管理和洞察到全渠道消费者互动、自动化智能营销以及敏捷营销实践，助力企业建立从方法论到实践落地的“数据驱动增长体系”，真正实现数字化营销增长模式。
        </p>
        <button @click="onapply()">免费试用</button>
      </div>
    </div>
    <div class="content">
      <div>
        <h6>数字化营销管理体系</h6>
        <p>
          全面数字化与目标客户及受众群体的触点，建立数字化链接<br />
          对非数字化的营销触点进行数字化升级<br />
          打通广告投放渠道和落地触点，实现流量的链路数字化<br />
          打通交易平台和触点，从POS、二维码到电商平台、线下门店
        </p>
        <button @click="onapply()">免费试用</button>
      </div>
      <img src="../../assets/images/market02.png" />
    </div>
    <div class="content">
      <img src="../../assets/images/market03.png" />
      <div>
        <h6>客户数据的数字化治理</h6>
        <p>
          全渠道客户数据的汇总、管理、识别与自动合并<br />
          定义客户生命周期模型，自动计算客户生命周期阶段<br />
          数据的多维度标签体系，自动化智能化打标签<br />
          通过AI智能数据模型进行数据挖掘，形成精准用户画像
        </p>
        <button @click="onapply()">免费试用</button>
      </div>
    </div>
    <div class="content">
      <div>
        <h6>数字化营销洞察和分析</h6>
        <p>
          洞察客户群体的状态、人群特征和时空分布<br />
          分析客户群体的增加与流失，掌握核心及长尾用户的<br />
          智能化分析哪些渠道或营销手段的拉新、留存和转化更好<br />
          智能化洞察客户购买频次、购买偏好和购买动机
        </p>
        <button @click="onapply()">免费试用</button>
      </div>
      <img src="../../assets/images/market04.png" />
    </div>
    <div class="content_1">
      <div class="content_l">
        <h6>全渠道的消费者互动</h6>
        <p>私域流量全覆盖，完整提供营销服务闭环和解决方案</p>
        <button @click="onapply()">免费试用</button>
      </div>
      <div class="content_r">
        <div>
          <img src="../../assets/images/wx01.png" />
          <p><span>微信/企业微信营销</span>流量变留量，存量变增量</p>
        </div>
        <div>
          <img src="../../assets/images/wx02.png" />
          <p><span>短信/彩信营销推送</span>时效性强</p>
        </div>
        <div>
          <img src="../../assets/images/wx03.png" />
          <p><span>自动化邮件营销</span>高送达率</p>
        </div>
        <div>
          <img src="../../assets/images/wx04.png" />
          <p><span>App消息推送</span>提高用户粘性</p>
        </div>
        <div>
          <img src="../../assets/images/wx05.png" />
          <p><span>定向广告推送</span>稳定性高</p>
        </div>
        <div>
          <img src="../../assets/images/wx.png" />
          <p><span>小程序营销推广</span>即用即走，无需下载</p>
        </div>
      </div>
    </div>
    <div class="content_2">
      <img src="../../assets/images/market05.png" />
      <div>
        <h6>智能化营销运营、优化、预测和决策</h6>
        <p>
          围绕关键营销时刻（Moment of Truth）的自动化营销流程<br />
          客户旅程（Customer Journey）的智能化可视化设计和执行<br />
          Always on的全天候营销策略，7x24小时不间断营销<br />
          Ad Hub智能化广告投放和效果优化管理<br />
          AI智能商品推荐，实现产品/商品与用户/消费者的完美匹配<br />
          帮助用户建立基于大数据、云计算和AI的营销决策体系
        </p>
        <button @click="onapply()">免费试用</button>
      </div>
    </div>
    <div class="foot">
      <h6>500＋企业的共同选择</h6>
      <p>千人千面的策略布置</p>
      <img src="../../assets/images/hzqy.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  props: ["childEvent"],
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {
    onapply() {
      // this.childEvent(true);
      window.location.href = "http://xdnmxba7z7d6llfo.mikecrm.com/uMx7QGf";
    },
  },
};
</script>

<style scoped lang="less">
.heading {
  height: 6.6rem;
  background-image: url(../../assets/images/digitization01.png);
  background-size: 100% 100%;
  text-align: center;
  color: #fff;
  h3 {
    font-size: 0.56rem;
    padding-top: 2.05rem;
  }
  p {
    text-align: left;
    width: 7.43rem;
    margin: 0.18rem auto;
    font-size: 0.2rem;
    line-height: 0.30rem;
  }
  button {
    width: 1.48rem;
    height: 0.64rem;
    background-color: #ffc53d;
    border: 0;
    color: #fff;
    font-size: 0.2rem;
    margin-top: 0.4rem;
    border-radius: 0.05rem;
  }
}
.content {
  display: flex;
  justify-content: center;
  padding: 1rem 0;
  img {
    width: 7.08rem;
    height: 4.38rem;
  }
  div {
    width: 7.32rem;
    padding-left: 0.88rem;
    padding-top: 0.38rem;
    h6 {
      font-size: 0.28rem;
      line-height: 0.42rem;
      color: #333;
      padding-bottom: 0.32rem;
    }
    p {
      font-size: 0.18rem;
      color: #666;
      padding-bottom: 0.12rem;
      line-height: 0.27rem;
    }
    button {
      width: 1.48rem;
      height: 0.64rem;
      background-color: #333;
      color: #ffc53d;
      border-radius: 0.05rem;
    }
  }
}
.content:nth-child(2n + 1) {
  background-color: #fffff5;
  padding: 0.68rem 0;
  div {
    width: 6.32rem;
  }
}
.content_1 {
  display: flex;
  justify-content: space-between;
  padding: 1rem 2.33rem;
  .content_l {
    width: 14rem;
    padding-top: 0.97rem;
    h6 {
      font-size: 0.28rem;
      line-height: 0.42rem;
      color: #333;
      padding-bottom: 0.2rem;
    }
    p {
      font-size: 0.18rem;
      color: #666;
      padding-bottom: 0.12rem;
    }
    button {
      width: 1.48rem;
      height: 0.64rem;
      background-color: #333;
      color: #ffc53d;
      border-radius: 0.05rem;
    }
  }
  .content_r {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    div {
      width: 48%;
      box-sizing: border-box;
      display: flex;
      box-shadow: 10px 10px 30px 1px rgba(0, 0, 115, 0.13);
      padding: 0.24rem 1rem 0.24rem 0.38rem;
      margin-bottom: 0.3rem;
      border-radius: 0.08rem;
      img {
        width: 0.64rem;
        height: 0.64rem;
        padding-right: 0.3rem;
      }
      p {
        font-size: 0.16rem;
        color: #666;
        span {
          padding: 0.05rem 0 0.08rem;
          font-size: 0.22rem;
          color: #333;
          font-weight: 600;
          line-height: 0.34rem;
          display: block;
        }
      }
    }
  }
}
.content_2 {
  display: flex;
  justify-content: center;
  padding: 1rem 0;
  img {
    width: 7.08rem;
    height: 4.38rem;
  }
  div {
    width: 7.32rem;
    padding-left: 0.58rem;
    padding-top: 0.38rem;
    h6 {
      font-size: 0.28rem;
      line-height: 0.42rem;
      color: #333;
      padding-bottom: 0.32rem;
    }
    p {
      font-size: 0.18rem;
      color: #666;
      padding-bottom: 0.12rem;
      line-height: 0.27rem;
    }
    button {
      width: 1.48rem;
      height: 0.64rem;
      background-color: #333;
      color: #ffc53d;
      border-radius: 0.05rem;
    }
  }
}
.foot {
  h6 {
    font-size: 0.28rem;
    text-align: center;
    padding-bottom: 0.2rem;
  }
  p {
    font-size: 0.18rem;
    color: #666;
    text-align: center;
  }
  img {
    width: 19.2rem;
    height: 7.88rem;
  }
}
</style>
